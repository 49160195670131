import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
export default (function () {
  return new Promise(function (resolve, reject) {
    var _window$google, _window$google$maps;
    if (process.server) {
      reject(new Error('Google Maps cannot be loaded on the server'));
    }

    // If already loaded, resolve immediately
    if ((_window$google = window.google) !== null && _window$google !== void 0 && (_window$google$maps = _window$google.maps) !== null && _window$google$maps !== void 0 && _window$google$maps.places) {
      resolve(window.google);
      return;
    }
    var googleApiKey = useRuntimeConfig().googleApiKey;

    // Check if script is already in the process of loading
    if (document.querySelector('script[src*="maps.googleapis.com"]')) {
      // Wait for the global google object to be available
      var checkGoogleLoaded = function checkGoogleLoaded() {
        var _window$google2, _window$google2$maps;
        if ((_window$google2 = window.google) !== null && _window$google2 !== void 0 && (_window$google2$maps = _window$google2.maps) !== null && _window$google2$maps !== void 0 && _window$google2$maps.places) {
          resolve(window.google);
        } else {
          setTimeout(checkGoogleLoaded, 100);
        }
      };
      checkGoogleLoaded();
      return;
    }

    // If not loaded, add the script
    var script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=".concat(googleApiKey, "&libraries=places");
    script.async = true;
    script.onload = function () {
      var _window$google3, _window$google3$maps;
      if ((_window$google3 = window.google) !== null && _window$google3 !== void 0 && (_window$google3$maps = _window$google3.maps) !== null && _window$google3$maps !== void 0 && _window$google3$maps.places) {
        resolve(window.google);
      } else {
        reject(new Error('Google Maps script loaded, but google object is not available'));
      }
    };
    script.onerror = function () {
      reject(new Error('Failed to load Google Maps script'));
    };
    document.head.appendChild(script);
  });
});